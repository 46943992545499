import React from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'
import { StaticQuery, graphql } from "gatsby"
import { Link, Typography } from "@material-ui/core"

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

const PageContent = styled.main`
  flex: 1;
  align-self: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

const Footer = styled.footer`
  text-align: center;
  padding: 8px 0;
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={() => (
      <PageContainer>
        <PageContent>
          {children}
        </PageContent>
        <Footer>
          <Typography variant="caption" color="textSecondary">
            © {new Date().getFullYear()} rahmathd, Built with ❤ and
            {` `}
            <Link href="https://www.gatsbyjs.org">Gatsby</Link>
          </Typography>
        </Footer>
      </PageContainer>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
